import Vue from 'vue'
import $ from 'jquery'
import Vuex from 'vuex'
import router from '@/router'
import Swal from 'sweetalert2'
import imgError from '@/assets/cliente/img/error.png'
import source from '../../public/source.json' // Apenas desenvolvimento

Vue.use(Vuex)

const store = {
   state: {
      // urlRest: 'http://10.1.1.237:8080/CCM-0.0.1/',
      // urlSocketChat: 'ws://10.1.1.237:8080/CCM-0.0.1/socket/chat',
      urlRest: 'https://ccmdobrasil.com.br/CCM/',
      urlSocketChat: 'wss://ccmdobrasil.com.br/CCM/socket/chat',
      source: source,
      isRouteAdmin: false,
      isCarregando: true,
      logado: {'cliente': false, 'admin': false, 'token': localStorage.token},
      dadosUsuario: {'cliente': {}, 'admin': {}},
      clienteData: {'lancamentos': [], 'noticias': [], 'categorias': [], 'marcas': [], 'depoimentos': [], 'banners': []},
      carrinho: {'itens': [], 'valorTotal': 0, 'toggle': false, 'variacoes': []},
      pesquisa: {'categoria': '', 'subcategoria': '', 'tipoPesquisa': 'Geral', 'valor': null, 'historico': [], 'resultado': [], 'filtros': [], 'relacionados': []},
      pesquisaDesabilitado: false,
      codigosComparacao: localStorage.codigosComparacao == null ? [] : JSON.parse(localStorage.codigosComparacao),
      routerHistory: [],
      firstRoute: null,
      timerBlog: 100
   },
   mutations: {
      saveHistory : (state, route) => {
         if (state.routerHistory.length == 0) {
            state.firstRoute = route;
         }

         state.routerHistory.push(route)
         window.scrollTo(0, 0)
      },
      isRouteAdmin : (state, isAdmin) => {
         state.isRouteAdmin = isAdmin
         document.body.classList.remove('admin', 'cliente');
         document.body.classList.add(isAdmin ? 'admin' : 'cliente');

         if (isAdmin) {
            if (localStorage.ccmAdminTheme != null && localStorage.ccmAdminColor != null) {
               $("html").attr("class", localStorage.ccmAdminColor +" color-header")
            } else {
               localStorage.ccmAdminColor = 'headercolor1'
               $("html").attr("class", 'headercolor1 color-header')
            }
         }
      },
      login : (state, credenciais) => {
         if (localStorage.usernameCliente != undefined && localStorage.senhaCliente != undefined) {
            credenciais.usuario = localStorage.usernameCliente.split(/(\w\w)/g).filter(p => !!p).map(c => String.fromCharCode(parseInt(c, 16))).join("")
            credenciais.senha = localStorage.senhaCliente.split(/(\w\w)/g).filter(p => !!p).map(c => String.fromCharCode(parseInt(c, 16))).join("")
         }

         if (credenciais.redirect) {
            if (router.options.routes.filter(rota => rota.name == state.firstRoute.name)[0] == null) {
               router.push('/').catch(function(){})
            } else {
               router.push(state.firstRoute.path).catch(function(){})
            }
         }

         // if (!credenciais.redirect) {
            state.isCarregando = true;
         // }

         Vue.prototype.$axios({
            method: 'get',
            url: state.urlRest +'login',
            params: {
               cnpj: credenciais.usuario,
               senha: credenciais.senha
            }
         }).then(function (response) {
            if (response.status != 206) {
               state.dadosUsuario.cliente = response.data.usuario
               state.logado.token = ('Bearer '+ response.data.usuario.token +'@@@'+ response.data.usuario.email)
               state.logado.cliente = true;
               localStorage.usernameCliente = credenciais.usuario.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("")
               localStorage.senhaCliente = credenciais.senha.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("")
               localStorage.token = state.logado.token;
               store.mutations.getCarrinho(state)
            }

            store.mutations.saveData(state, response.data.dados)

            if (!credenciais.redirect) {
               router.push('/perfil').catch(function(){})
            }
         }).catch(function (error) {
            store.mutations.saveData(state, error.response.data.dados)
            store.mutations.deslogar(state)

            if (!credenciais.redirect) {
               if (error.response != undefined) {
                  if (error.response.status == 401) {
                     Vue.prototype.$toast.fire({
                        icon: 'error',
                        title: 'Credenciais incorretas!'
                     });
                  } else if (error.response.status == 403) {
                     Swal.fire({
                        icon: 'error',
                        title: 'Conta bloqueada!',
                        text: 'Número de tentativas excedido.'
                     });
                  } else {
                     Vue.prototype.$toast.fire({
                        icon: 'error',
                        title: 'Erro: ' + error.response.status
                     });
                  }
               } else {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            }
         }).finally(() => {
            // if (!credenciais.redirect) {
               state.isCarregando = false;
            // }
         });
      },
      loginAdmin : (state, credenciais) => {
         if (localStorage.username != undefined && localStorage.senha != undefined) {
            credenciais = {
               "usuario": localStorage.username.split(/(\w\w)/g).filter(p => !!p).map(c => String.fromCharCode(parseInt(c, 16))).join(""),
               "senha": localStorage.senha.split(/(\w\w)/g).filter(p => !!p).map(c => String.fromCharCode(parseInt(c, 16))).join("")
            };
         }

         if (credenciais == undefined) {
            return
         } else {
            state.isCarregando = true
         }

         Vue.prototype.$axios({
            method: 'get',
            url: state.urlRest +'admin/login',
            params: {
               login: credenciais.usuario,
               senha: credenciais.senha
            }
         }).then(function (response) {
            state.dadosUsuario.admin = response.data
            state.logado.token = ('Bearer '+ response.data.tokenSessao +'@@@'+ response.data.login)
            state.logado.admin = true;

            if (credenciais.manterConectado != false) {
               localStorage.username = credenciais.usuario.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("")
               localStorage.senha = credenciais.senha.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("")
               localStorage.token = state.logado.token;
            }

            router.push('/admPaginaInicial').catch(function(){})

         }).catch(function (error) {
            store.mutations.deslogarAdmin(state)

            if (error.response != undefined) {
               if (error.response.status == 401) {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Credenciais incorretas!'
                  });
               } else {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            state.isCarregando = false
         });
      },
      deslogar : state => {
         state.dadosUsuario.admin = {}
         state.logado.token = null
         state.logado.admin = false
         localStorage.removeItem('username')
         localStorage.removeItem('senha')
         
         state.dadosUsuario.cliente = {}
         state.logado.token = null
         state.logado.cliente = false
         localStorage.removeItem('usernameCliente')
         localStorage.removeItem('senhaCliente')
         localStorage.removeItem('token')

         if (router.currentRoute.path.includes("perfil")) {
            router.push('/login')
         }
      },
      deslogarAdmin : state => {
         state.dadosUsuario.cliente = {}
         state.logado.token = null
         state.logado.cliente = false
         localStorage.removeItem('usernameCliente')
         localStorage.removeItem('senhaCliente')
         
         state.dadosUsuario.admin = {}
         state.logado.token = null
         state.logado.admin = false
         localStorage.removeItem('username')
         localStorage.removeItem('senha')
         localStorage.removeItem('token')

         router.push('/').catch(function(){})
      },
      alternarTelaCarregamento : (state, isCarregando) => {
         state.isCarregando = isCarregando;
      },
		saveData : (state, data) => {
         state.clienteData = data;

         if (data.text != null) {
            state.source = data.text;
         }

         if (data.noticias.length > 0) {
            if (data.noticias.length == 3) {
               state.clienteData.noticias.push(data.noticias[0])
               
            } else if (data.noticias.length == 2) {
               state.clienteData.noticias.push(...data.noticias)
               
            } else if (data.noticias.length == 1) {
               state.clienteData.noticias.push(data.noticias[0])
               state.clienteData.noticias.push(data.noticias[0])
               state.clienteData.noticias.push(data.noticias[0])
            }
            
            setInterval(() => {
               if (state.timerBlog == 0) {
                  state.timerBlog = 100;
                  
                  let item  = state.clienteData.noticias[0];
                  state.clienteData.noticias.splice(0, 1)
                  state.clienteData.noticias.push(item)
               }
   
               state.timerBlog--;
            }, 100)
         }
      },
      setPesquisa : (state, pesquisa) => {
         state.pesquisa = {
            'categoria': pesquisa.categoria,
            'subcategoria': pesquisa.subcategoria,
            'tipoPesquisa': pesquisa.tipoPesquisa,
            'valor': pesquisa.valor,
            'historico': [],
            'resultado': [],
            'filtros': [],
            'relacionados': []
         }

         store.mutations.pesquisar(state, pesquisa)
      },
      pesquisar : (state, pesquisa) => {
         let jaPesquisado = state.pesquisa.historico.map(pesquisa => pesquisa.valor.toUpperCase()).includes(String(pesquisa.valor).trim().toUpperCase());
         
         if (pesquisa.tipoPesquisa != 'Desabilitados') {
            if (jaPesquisado) {
               return
            } else if (pesquisa.valor != null && String(pesquisa.valor).trim().length > 0) {
               state.pesquisa.historico.push(JSON.parse(JSON.stringify(pesquisa)))
            }
         }
         
         state.isCarregando = true;
         localStorage.ccmPesquisa = JSON.stringify(state.pesquisa.historico)
         
         Vue.prototype.$axios({
            method: 'post',
            url: state.urlRest +'site/searchProdutos',
            headers: {
					'Content-Type': 'application/json'
            },
				data: state.pesquisa.historico,
            params: {
               desabilitados: state.pesquisaDesabilitado
            }
         }).then(response => {
            state.pesquisa.resultado = response.data.resultado;
            state.pesquisa.filtros = response.data.filtros;
            state.pesquisa.relacionados = response.data.relacionados;
            
         }).catch(function (error) {
            if (error.response != undefined) {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
            } else {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            router.push('/produtos').catch(function(){})
            state.isCarregando = false;

            $(".offcanvas__area").removeClass("opened");
            $(".body-overlay").removeClass("opened");
         });
      },
      deletePesquisa : (state, index) => {
         state.pesquisa.historico.splice(index, 1)
         store.mutations.pesquisar(state, {
            'categoria': state.pesquisa.categoria,
            'subcategoria': state.pesquisa.subcategoria,
            'tipoPesquisa': state.pesquisa.tipoPesquisa,
            'valor': null
         })
      },
      resgatarPesquisa : (state, pesquisa) => {
         state.pesquisa.historico = pesquisa;
      },
      togglePesquisarDesabilitados : state => {
         state.pesquisaDesabilitado = !state.pesquisaDesabilitado
      },
      toggleCarrinho : (state, toggle) => {
         state.carrinho.toggle = toggle

         if (toggle) {
            $(".cart__toggle").addClass("cart__toggle-open");

         } else {
            $(".cart__toggle").removeClass("cart__toggle-open");
            $(".cart__mini").removeClass("cart__opened");
         }

         $(".cart__mini.cart__opened").animate({ scrollTop: $(".cart__mini.cart__opened ul").height() }, 0);
      },
      getCarrinho : state => {
         try {
            state.carrinho.valorTotal = JSON.parse(localStorage.carrinho).itens.map(produto => parseFloat(produto.precoComImposto) * parseFloat(produto.quantidade)).reduce((total, valor) => total += valor, 0)
            state.carrinho.itens = JSON.parse(localStorage.carrinho).itens
            state.carrinho.toggle = false;
            
         } catch (e) {
            state.carrinho = {'itens': [], 'valorTotal': 0, 'toggle': false, 'variacoes': []}
         }

         Vue.prototype.$axios({
            method: 'get',
            url: state.urlRest +'site/getCarrinho',
            
         }).then(response => {
            state.carrinho.itens = response.data;
            state.carrinho.valorTotal = response.data.map(produto => parseFloat(produto.precoComImposto) * parseFloat(produto.quantidade)).reduce((total, valor) => total += valor, 0)
         })
      },
      limparCarrinho : state => {
         state.carrinho = {'itens': [], 'valorTotal': 0, 'toggle': false, 'variacoes': []}
         localStorage.carrinho = JSON.stringify(state.carrinho)
         store.mutations.saveCarrinho(state)
      },
      alterarQuantidadeCarrinho : (state, produto) => {
         state.carrinho.itens.forEach(item => {
            if (item.codigo == produto.codigo) {
               item.quantidade = produto.quantidade
               item.total = parseFloat(produto.preco) * parseFloat(produto.quantidade)
               item.totalComImposto = parseFloat(produto.precoComImposto) * parseFloat(produto.quantidade)
               item.totalIPI = parseFloat(produto.valorIPI) * parseFloat(produto.quantidade)
               item.totalICMS = parseFloat(produto.valorICMS) * parseFloat(produto.quantidade)
            }
         });

         localStorage.carrinho = JSON.stringify(state.carrinho)
         state.carrinho.valorTotal = state.carrinho.itens.map(produto => parseFloat(produto.precoComImposto) * parseFloat(produto.quantidade)).reduce((total, valor) => total += valor)
         store.mutations.saveCarrinho(state)
      },
      removeCarrinho : (state, index) => {
         state.carrinho.itens.splice(index, 1)
         state.carrinho.valorTotal = state.carrinho.itens.map(produto => parseFloat(produto.precoComImposto) * parseFloat(produto.quantidade)).reduce((total, valor) => total += valor, 0)
         localStorage.carrinho = JSON.stringify(state.carrinho)
         store.mutations.saveCarrinho(state)
         
         Vue.prototype.$toast.fire({
            icon: 'success',
            title: 'Removido do carrinho'
         });
      },
      addCarrinho : (state, produto) => {
         let verificaExistencia = state.carrinho.itens.findIndex(item => item.codigo == produto.codigo)
         let precoComImposto = parseFloat(produto.preco) + parseFloat(produto.valorICMS) + parseFloat(produto.valorIPI)
         
         if (verificaExistencia != -1) {
            let item = state.carrinho.itens[verificaExistencia];
            state.carrinho.itens.splice(verificaExistencia, 1)
            produto.quantidade += item.quantidade;
         }

         state.carrinho.itens.unshift({
            'foto': produto.foto == null ? produto.fotos == null || produto.fotos[0] == null ? '' : produto.fotos[0].url : produto.foto, 
            'nome': produto.nome,
            'codigo': produto.codigo,
            'quantidade': produto.quantidade,
            'qtdMult': produto.qtdMult,
            'preco': parseFloat(produto.preco),
            'valorICMS': parseFloat(produto.valorICMS),
            'valorIPI': parseFloat(produto.valorIPI),
            'precoComImposto': precoComImposto,
            'total': parseFloat(produto.preco) * parseFloat(produto.quantidade),
            'totalComImposto': precoComImposto * parseFloat(produto.quantidade),
            'totalIPI': parseFloat(produto.valorIPI) * parseFloat(produto.quantidade),
            'totalICMS': parseFloat(produto.valorICMS) * parseFloat(produto.quantidade)
         })

         state.carrinho.valorTotal = state.carrinho.itens.map(produto => parseFloat(produto.precoComImposto) * parseFloat(produto.quantidade)).reduce((total, valor) => total += valor, 0)
         localStorage.carrinho = JSON.stringify(state.carrinho)
         store.mutations.saveCarrinho(state)
         
         Vue.prototype.$toast.fire({
            icon: 'success',
            title: 'Adicionado ao carrinho'
         });
      },
      saveCarrinho : state => {
         Vue.prototype.$axios({
            method: 'post',
            url: state.urlRest +'site/saveCarrinho',
            headers: {
					'Content-Type': 'application/json'
            },
				data: state.carrinho.itens
         })
      },
      compararProdutos : (state, codigo) => {
         let index  = state.codigosComparacao.findIndex(c => String(c).replace("-", "") == String(codigo).replace("-", ""))

         if (index == -1) {
            state.codigosComparacao.push(codigo)
         } else {
            state.codigosComparacao.splice(index, 1)
         }

         localStorage.codigosComparacao = JSON.stringify(state.codigosComparacao)
      },
      setCarrinhoVariacoes : (state, variacoes) => {
         state.carrinho.variacoes = variacoes
      }
   },
   actions: {
      /* eslint-disable-next-line no-unused-vars */
      imageError : ({commit}, e) => {
         e.target.src = imgError;
      },
      /* eslint-disable-next-line no-unused-vars */
      buscarCEP : ({commit}, cep) => {
         return new Promise((resolve, reject) => {
            commit('alternarTelaCarregamento', true)

            Vue.prototype.$axios({
               method: 'get',
               url: 'https://viacep.com.br/ws/'+ cep +'/json/'

            }).then(response => {
               if (response.data.erro) {
                  reject(null)
               } else {
                  resolve(response.data)
               }
            }).catch(function () {
               reject(null)
            }).finally(function () {
               commit('alternarTelaCarregamento', false)
            });
         })
      },
      /* eslint-disable-next-line no-unused-vars */
      buscarCNPJ : ({commit}, cnpj) => {
         return new Promise((resolve, reject) => {
            commit('alternarTelaCarregamento', true)

            Vue.prototype.$axios({
               method: 'get',
               url: 'https://publica.cnpj.ws/cnpj/'+ cnpj

            }).then(response => {
               resolve(response.data)
            }).catch(function () {
               reject(null)
            }).finally(function () {
               commit('alternarTelaCarregamento', false)
            });
         })
      },
      isRouteAdmin : ({commit}, isAdmin) => {
         commit('isRouteAdmin', isAdmin)
      },
      saveHistory : ({commit}, route) => {
         commit('saveHistory', route)
      },
      login : ({commit}, credenciais) => {
         commit('login', credenciais)
      },
      loginAdmin : ({commit}, credenciais) => {
         commit('loginAdmin', credenciais)
      },
      alternarTelaCarregamento : ({commit}, isCarregando) => {
         commit('alternarTelaCarregamento', isCarregando)
      },
      setPesquisa : ({commit}, pesquisa) => {
         commit('setPesquisa', pesquisa)
      },
      pesquisar : ({commit}, pesquisa) => {
         commit('pesquisar', pesquisa)
      },
      deletePesquisa : ({commit}, index) => {
         commit('deletePesquisa', index)
      },
      resgatarPesquisa : ({commit}, pesquisa) => {
         commit('resgatarPesquisa', pesquisa)
      },
      toggleCarrinho : ({commit}, toggle) => {
         commit('toggleCarrinho', toggle)
      },
      addCarrinho : ({commit}, produto) => {
         if (router.currentRoute.name != 'Produto' && !$("#modalVariacoes").hasClass("show")) {
            commit('alternarTelaCarregamento', true)

            Vue.prototype.$axios({
               method: 'get',
               url: store.state.urlRest +'site/getProdutoVariacoes',
               params: {
                  codigo: produto.codigo
               }
            }).then(response => {
               if (response.data.length > 0) {
                  commit('setCarrinhoVariacoes', response.data)
                  $("#modalVariacoes").modal("show")
               } else {
                  commit('addCarrinho', produto)
               }
            }).catch((error) => {
               if (error.response != undefined) {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               } else {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            }).finally(() => {
               commit('alternarTelaCarregamento', false)
            });
         } else {
            commit('addCarrinho', produto)
         }
      },
      removeCarrinho : ({commit}, index) => {
         commit('removeCarrinho', index)
      },
      alterarQuantidadeCarrinho : ({commit}, produto) => {
         commit('alterarQuantidadeCarrinho', produto)
      },
      compararProdutos : ({commit}, codigo) => {
         commit('compararProdutos', codigo)
      },
      togglePesquisarDesabilitados : context => context.commit('togglePesquisarDesabilitados'),
      limparCarrinho : context => context.commit('limparCarrinho'),
      deslogarAdmin : context => context.commit('deslogarAdmin'),
      getCarrinho : context => context.commit('getCarrinho'),
      deslogar : context => context.commit('deslogar')
   },
   modules: {
   }
}

export default new Vuex.Store(store);